<template>
  <v-card
    color="basil"
    flat
    class="fill-height"
  >
    <v-card-text v-if="code" class="fill-height">
      <v-textarea :value="value" solo class="d-block fill-width fill-height body-2" @keyup="UpdateInformation($event)"></v-textarea>
    </v-card-text>
    <v-card-text v-else>
      <section v-html="value" />
    </v-card-text>
  </v-card>
</template>

<script>
let updateTimeout = null
export default {
  props: {
    code: {
      type: Boolean,
      default: false
    },
    keyType: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
  }),
  methods: {
    UpdateInformation (e) {
      const key = this.keyType
      if (updateTimeout) {
        clearTimeout(updateTimeout)
      }
      updateTimeout = setTimeout(() => {
        const value = e.target.value || ''
        this.$emit('input', value)
        const params = {
          id: 1
        }
        params[key] = value
        this.$store.dispatch('SETTING_PROCESS', params)
      }, 500)
    }
  }
}
</script>
